 //Mutations (functions that effect the STATE)
import state from "./state";

export default {
    SET_LANGUAGE(state: any, language: any) {
        state.currentLanguage = language;
    },

    //test
    //up(state: any) {
    //    state.count = state.count + 1
    //}
}