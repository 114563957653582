import { store } from '../store/store';
import auth from 'authur';
import { EventModel, MemberApiClient, PublicationModel } from '../generated';
import { defineComponent } from 'vue';

export const SharedMixin = defineComponent({
    computed: {
        isLoggedIn: function () {
            return localStorage.getItem("loggedIn") == 'true';
        }
    },
    data() {
        return {
            favoriteEvents: localStorage.getItem("favoriteEvents"),
            favoritePublications: localStorage.getItem("favoritPublications"),
        }
    },
    methods: {
        getState: function (state: string) {
            if (state === "Update")
                return store.state.translations.Update;
            else if (state === "New") {
                return store.state.translations.New;
            }

            return "";
        },
        setCookie: function (name: string, val: string) {
            const date = new Date();
            const value = val;

            // Set it expire in 90 days
            date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000));

            // Set it
            document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
        },
        getCookie: function (cname: string) {
            const name = cname + "=",
                decodedCookie = decodeURIComponent(document.cookie),
                ca = decodedCookie.split(';');

            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return 'null';
        },
        deleteCookie: function (name: string) {
            const date = new Date();

            // Set it expire in -1 days
            date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

            // Set it
            document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
        },
        setFavoriteEvent(event: EventModel) {
            const client = new MemberApiClient();

            client.setFavorite(event.id).then(result => {

                if (this.favoriteEvents != undefined) {
                    const favArray = this.favoriteEvents.split(",");
                    favArray.push(event.id.toString());
                    this.favoriteEvents = favArray.join();
                    localStorage.setItem('favoriteEvents', this.favoriteEvents);
                }
            });
        },
        removeFavoriteEvent(event: EventModel) {
            const client = new MemberApiClient();
            client.removeFavorite(event.id).then(result => {
                if (result != null) {

                    if (this.favoriteEvents != undefined) {
                        const favArray = this.favoriteEvents.split(",");

                        const index = favArray.indexOf(event.id.toString());
                        if (index > -1) {
                            favArray.splice(index, 1);
                        }

                        this.favoriteEvents = favArray.join();
                        localStorage.setItem('favoriteEvents', this.favoriteEvents);
                    }
                }
            });

        },
        removeFavoritePub(publication: PublicationModel) {
            const client = new MemberApiClient();
            client.removeFavoritePublication(publication.id);

            if (this.favoritePublications != undefined) {
                const favArray = this.favoritePublications.split(",");

                const index = favArray.indexOf(publication.id.toString());
                if (index > -1) {
                    favArray.splice(index, 1);
                }

                this.favoritePublications = favArray.join();
                localStorage.setItem('favoritePublications', this.favoritePublications);
            }
        }
    }
})