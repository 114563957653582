import { createStore } from 'vuex';
import state from "./state";
import mutations from "./mutations";

export const store = createStore({
    state,
    mutations,

    //Actions: functions that can be called throughout app (by "dispatching" the action) that call on mutations
    //wil ik eigenlijk in file actions.ts plaatsen, maar TS issues :')
    actions: {
        setLanguage({ commit }, language) {
            commit('SET_LANGUAGE', language);
        },
    },

});
 