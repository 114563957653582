
    import { defineComponent } from 'vue';
    import { IonPage, IonContent, IonTabBar, IonTabButton, IonIcon, IonLabel, IonHeader, IonToolbar, IonButtons, IonTitle } from '@ionic/vue';
    import { homeSharp, calendar, bookSharp, heartSharp, personCircleSharp, arrowBackOutline } from 'ionicons/icons';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { SharedMixin } from '@/mixins/SharedMixin';
    import { EventModel, MemberApiClient, EventApiClient, EventCategoryModel } from '../generated';
    import { store } from '../store/store';

    export default defineComponent({
        props: ['toolbarVisible', 'favoriteEmptyIconVisible', 'tabbarVisible', 'toolbarTitle', 'favoriteSelected'],
        mixins: [LanguageMixin, SharedMixin],
        components: {
            IonPage,
            IonContent,
            IonTabBar,
            IonTabButton,
            IonLabel,
            IonHeader,
            IonToolbar,
            IonButtons,
            IonIcon,
            IonTitle
        },
        watch: {
            '$route.params': {
                handler(newValue) {
                    const { eventdetail } = newValue;
                    if (eventdetail) {
                        this.loadData();
                    }
                },
                immediate: true,
            },
            'this.ionClass': {
                handler(newValue) {
                    this.loadData()
                },
                immediate: true,
            }
        },
        data() {
            return {
                eventId: this.$route.params.event as string,
                eventdetailId: this.$route.params.eventdetail as string,
                currentEvent: new EventCategoryModel(),
                currentEventDate: new EventModel(),
                loggedIn(): boolean {
                    return localStorage.getItem('loggedIn') == 'true';
                },
                ionClass: '',
                yellowBackground(): boolean {
                    // @ts-ignore
                    const trimmedPath = this.$route.fullPath.split("?")[0];
                    return trimmedPath == "/login" || trimmedPath == "/activate" || trimmedPath == "/register" ||  trimmedPath == "/resetpassword" || trimmedPath == "/privacy"
                } 
            }
        },
        mounted() {
            if (this.eventId) {
                const eventsClient = new EventApiClient();
                eventsClient.get(parseInt(this.eventId), store.state.currentLanguage).then(result => {
                    if (result != null) {
                        this.currentEvent = result;

                        if (this.currentEvent.eventDates != undefined) {
                            const eventDates = this.currentEvent.eventDates.filter(e => e.id === parseInt(this.eventdetailId));
                            this.currentEventDate = eventDates[0];

                            if (this.currentEventDate) {
                                // @ts-ignore
                                if (this.favoriteEvents.indexOf(this.currentEventDate.id) > -1) {
                                    this.ionClass = "--filled";
                                } else {
                                    this.ionClass = "--empty";
                                }
                            }
                           
                        }
                    }
                });
            }
            
        },

        setup() {
            return {
                homeSharp,
                calendar,
                bookSharp,
                heartSharp,
                personCircleSharp,
                arrowBackOutline
            }
        },
        methods: {
            loadData() {
                if (this.eventId) {
                    setTimeout(() => {
                        const eventsClient = new EventApiClient();
                        eventsClient.get(parseInt(this.eventId), store.state.currentLanguage).then(result => {
                            if (result != null) {
                                this.currentEvent = result;

                                if (this.currentEvent.eventDates != undefined) {
                                    const eventDates = this.currentEvent.eventDates.filter(e => e.id === parseInt(this.eventdetailId));
                                    this.currentEventDate = eventDates[0];
                                }
                            }
                        });

                        if (this.currentEventDate) {
                            // @ts-ignore
                            if (this.favoriteEvents.indexOf(this.currentEventDate.id) > -1) {
                                this.ionClass = "--filled";
                            } else {
                                this.ionClass = "--empty";
                            }
                        }
                      
                    }, 200);
                }
                
               
            },
            setRoute: function (route: string) {
                // @ts-ignore
                this.$router.push(`/${route}`)
            },
            goBack() {
                    // @ts-ignore
                this.$router.go(-1)
            },
            changeFavorite() {
                if (this.currentEventDate) {
                    // @ts-ignore
                    if (this.favoriteEvents.indexOf(this.currentEventDate.id) > -1) {
                        this.ionClass = "--empty";
                        this.removeFavoriteEvent(this.currentEventDate)

                    } else {
                        this.ionClass = "--filled";
                        this.setFavoriteEvent(this.currentEventDate)
                    }
                }
                
            },
        }
    })

