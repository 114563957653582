import { createApp } from 'vue'
import App from './App.vue';
import BaseLayout from '@/templates/BaseLayout.vue';
import router from './router';

import { IonicVue } from '@ionic/vue';
import './registerServiceWorker'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';
import './theme/fonts.css';

//Vuex
import { store } from './store/store'
import auth from 'authur'; 

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store);

app.component('base-layout', BaseLayout);
console.log('auth', auth);

const authInitialize = async (callback: any) => {
    await auth.initialize({
        origin: 'https://forever-living-platform.euwest01.umbraco.io/',
        authPath: '/oauth/token',
        apiPath: '/umbraco/',
        persistenceGet: (key: string) => localStorage.getItem(key),
        persistenceSet: (key: string, val: string) => localStorage.setItem(key, val),
        persistenceClear: (storageKey: string) => localStorage.removeItem(storageKey),
        debug: false,
        events: {
        }
    })
    callback();
    auth.onAuthStateChange((status: any) => localStorage.setItem('loggedIn', status));
};

authInitialize((test: any) => {
    router.isReady().then(() => {
        app.mount('#app');
    });
});