import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/register',
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/login/privacy.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/login/register.vue')
    },
    {
        path: '/activate',
        name: 'Activate',
        component: () => import('@/views/login/activate.vue')
    },
    {
        path: '/resetpassword',
        name: 'Reset password',
        component: () => import('@/views/login/resetpassword.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/home.vue')
    },
    {
        path: '/events',
        name: 'Events',
        component: () => import('@/views/events/events.vue')
    },
    {
        path: '/events/:event',
        name: 'Events Overview',
        component: () => import('@/views/events/eventsOverview.vue')
    },
    {
        path: '/events/:event/:eventdetail',
        name: 'Events Detail',
        component: () => import('@/views/events/eventsDetail.vue')
    },
    {
        path: '/publications',
        name: 'Publications',
        component: () => import('@/views/publications/publications.vue')
    },
    {
        path: '/publications/:publication',
        name: 'Publication Detail',
        component: () => import('@/views/publications/publicationsDetail.vue')
    },
    {
        path: '/favorites',
        name: 'Favorites',
        component: () => import('@/views/favorites/favorites.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/profile/profile.vue')
    },
    {
        path: '/profile/language',
        name: 'Language',
        component: () => import('@/views/profile/language.vue')
    },
    {
        path: '/profile/settings',
        name: 'Settings',
        component: () => import('@/views/profile/settings.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
