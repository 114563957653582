import { TranslationsApiClient } from '../generated';
import { store } from '../store/store';

export const LanguageMixin = {
    computed: {
        currentTranslations() {
            return store.state.translations;
        },
    },
    methods: {
        changeLanguage: function(culture: string) {
            store.state.currentLanguage = culture;
            localStorage.setItem('blx-language', culture);

            const translationsApiClient = new TranslationsApiClient();
            translationsApiClient.getTranslations(store.state.currentLanguage).then(result => {
                if (result != null) {
                    store.state.translations = result;
                }
            });
        }
    }
}