import { TranslationsApiClient, EventApiClient, MemberModel } from '@/generated';

const loggedInUser = new MemberModel();

function getLang(lang: readonly string[], backup: string) {
    let result = '';
    const blxLang = localStorage.getItem('blx-language')
    let firstNavigatorLang = lang[0];

    if (firstNavigatorLang == "fr" || firstNavigatorLang == "fr-FR") {
        firstNavigatorLang = "fr-BE";
    }

    if (blxLang) {
        result = blxLang;
    } else if (firstNavigatorLang === 'nl-NL' || firstNavigatorLang === 'nl-BE' || firstNavigatorLang === 'fr-BE' || firstNavigatorLang === 'fr-LU') {
        localStorage.setItem('blx-language', firstNavigatorLang);
        result = firstNavigatorLang;
    } else {
        localStorage.setItem('blx-language', backup);
        result = backup;
    }

    return result
}

export default {
    currentLanguage: getLang(navigator.languages, 'nl-NL'),
    loggedInUser: loggedInUser,
    translations: {} as any //Has to be Any to dynamicly call translations name from Umbraco
};