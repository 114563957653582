
    import { IonApp, IonRouterOutlet } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { store } from '@/store/store';
    import { LanguageMixin } from '@/mixins/LanguageMixin';

    export default defineComponent({
        name: 'App',
        mixins: [LanguageMixin],
        components: {
            IonApp,
            IonRouterOutlet
        },
        beforeCreate: function () {
            LanguageMixin.methods.changeLanguage(store.state.currentLanguage ?? 'nl-NL');
        }
    });
